import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { AuthContext } from "context";
import DataTable from "examples/Tables/DataTable";
import toast from "react-hot-toast";
import MDButton from "components/MDButton";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import jsonToCsvExport from "json-to-csv-export";
import MDReportModal from "components/MDReportModal/MDReportModal";
import { MdAssignmentAdd } from "react-icons/md";
import MDHistoryModal from "components/MDHistoryModal/MDHistoryModal";
import { jwtDecode } from "jwt-decode";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import { Link } from 'react-router-dom';

const HospitalWideData = () => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [medicalReportData, setMedicalReportData] = useState("");
  const [openPatientReportModal, setOpenPatientReportModal] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [createdPatientId, setCreatedPatientId] = useState("");
  const [openPatientHistoryModal, setOpenPatientHistoryModal] = useState(false);
  const [historyPatientId, setHistoryPatientId] = useState("");
  const [historyPatient, setHistoryPatient] = useState({});
  const [noMessage, setNoMessage] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");

  const {
    userData: { token },
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClosePatientReportModal = () => {
    setOpenPatientReportModal(false);
  };

  useEffect(() => {
    if (!token) {
      navigate("/authentication/sign-in");
    } else {
      fetchHospitals();
    }
  }, [token, navigate]);

  useEffect(() => {
    if (selectedHospital) {
      fetchHospitalWideData();
    }
  }, [selectedHospital]);

  const handleChange = (value) => {
    setSelectedHospital(value);
    // fetchHospitalWideData();
  };

  const fetchHospitals = () => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/recommend-hospital`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setHospitals(data.hospitals);
          setSelectedHospital(data.hospitals[0]);
          // fetchHospitalWideData();
        }
      })
      .catch((error) => console.error("Error fetching hospitals:", error));
  };


  const fetchHospitalWideData = () => {
    setIsLoading(true);

    const token = localStorage.getItem("token");

    let physicianId = null;
    let userRole = null;
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        userRole = decodedToken.user.role;
        physicianId = decodedToken.user.id;
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
    const fetchedHospital = selectedHospital;
    fetch(
      `${
        userRole === "admin"
          ? `https://${process.env.REACT_APP_API_URL}/api/patient/filter-hospital-wide-data/${fetchedHospital}`
          : `https://${process.env.REACT_APP_API_URL}/api/patient/get-hospital-wide-data/${physicianId}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const patients = data.patients;
          if (data.patients.length > 0) {
            const tempColumns = [
              {
                Header: "Serial No",
                accessor: "sno",
                width: "10%",
                align: "left",
              },
              {
                Header: "Physician Name",
                accessor: "physicianName",
                width: "20%",
                align: "left",
              },
              {
                Header: "Physician Specialization",
                accessor: "specialization",
                width: "20%",
                align: "left",
              },
              {
                Header: "Patient Age",
                accessor: "age",
                width: "20%",
                align: "left",
              },
              {
                Header: "Patient Gender",
                accessor: "gender",
                width: "20%",
                align: "left",
              },
              // { Header: "Consent", accessor: "consent", width: "20%", align: "center" },
              // { Header: "Patient History", accessor: "actions", width: "30%", align: "center" },
              {
                Header: "Patient Report",
                accessor: "report",
                width: "30%",
                align: "center",
              },
              {
                Header: "Patient History",
                accessor: "history",
                width: "30%",
                align: "center",
              },
            ];
            const rows = patients.map((d, i) => ({
              sno: i + 1,
              physicianName: d.physician?.name || "N/A", // Handle missing data gracefully
              specialization: d.physician?.specialization || "N/A",
              age: calculateAge(d.dob),
              gender: d.gender.charAt(0).toUpperCase() + d.gender.slice(1),
              _id: d._id,
              // consent: d.consent,
              report: (
                <Box>
                  <MDButton onClick={() => handleOpenReport(d._id)}>
                    <MedicalInformationIcon style={{ color: "grey" }} />
                  </MDButton>
                </Box>
              ),
              history: (
                <MDButton onClick={() => handleAddHistory(d._id)}>
                  <MdAssignmentAdd />
                </MDButton>
              ),
            }));

            setTableData({ columns: tempColumns, rows });
          }

          if (jwtDecode(localStorage.getItem("token")).user.role !==
          "admin" && data.patients.length <= 10) {
            setNoMessage(true);
          }
        } else {
          toast.error(data.message);
        }
      })
      .catch(() => {
        toast.error("Error fetching hospital-wide data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleOpenReport = (id) => {
    setCreatedPatientId(id);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/medical-report/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setSuccessSB(true);
          setMedicalReportData(data.medicalReports[0]);
          setOpenPatientReportModal(true);
        } else {
          setResponseError(data.message || "Error getting patient report");
          setErrorSB(true);
          setMedicalReportData("");
          setOpenPatientReportModal(true);
        }
      })
      .catch(() => {
        setMedicalReportData("");
        setOpenPatientReportModal(true);
        setResponseError("Error getting patient report");
        setErrorSB(true);
      });
  };

  const handleAddHistory = (id) => {
    setHistoryPatientId(id);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/patient-history/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setSuccessSB(true);
          setHistoryPatient(data.patientHistory[0]);
          setOpenPatientHistoryModal(true);
          // setMedicalReportData(data.medicalReports[0])
          // setOpenPatientReportModal(true);
        } else {
          setOpenPatientHistoryModal(true);
        }
      })
      .catch(() => {
        setOpenPatientHistoryModal(true);
      });
  };

  const handleClosePatientHistoryModal = () => {
    setOpenPatientHistoryModal(false);
  };

  return (
    <DashboardLayout>
      {noMessage ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            textAlign: "center",
            bgcolor: "#f5f5f5",
            padding: 2,
          }}
        >
          <img
            src="https://www.nanyanglearning.com/common/images/no-records-found.jpg" // Replace with your image URL
            alt="404 Not Found"
            style={{ width: "300px", height: "auto", marginBottom: "20px" }}
          />
          <Typography variant="h4" gutterBottom>
            At least 10 patients required in hospital to view medical history
            data
          </Typography>
        </Box>
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Hospital-Wide Data Table
                  </MDTypography>
                </MDBox>

                <MDBox pt={3}>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "30px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : tableData.rows.length > 0 ? (
                    <>
                      {jwtDecode(localStorage.getItem("token")).user.role ===
                        "admin" && (
                        <MDBox sx={{ width: 180, ml: 170 }}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="view-select-label">
                              Select Hospital
                            </InputLabel>
                            <Select
                              labelId="view-select-label"
                              id="view-select"
                              value={selectedHospital}
                              label="Select Hospital"
                              onChange={(event) =>
                                handleChange(event.target.value)
                              }
                            >
                              {hospitals.map((h, index) => (
                                <MenuItem key={index} value={h}>
                                  {" "}
                                  {/* Use a unique key for each MenuItem */}
                                  {h}{" "}
                                  {/* Assuming `h` is the hospital name or some identifiable string */}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                      )}
                      <DataTable
                        table={tableData}
                        isSorted={true}
                        entriesPerPage={5}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    </>
                  ) : (
                    <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="h6" color="white">
                      Hospital-Wide Data Table
                    </MDTypography>
                  </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <MDReportModal
        open={openPatientReportModal}
        handleClose={handleClosePatientReportModal}
        // AddPatientReports={handleAddPatientReports}
        // EditPatientReports={handleEditPatientReports}
        reportData={medicalReportData}
        isDisabled={true}
      />
      <MDHistoryModal
        open={openPatientHistoryModal}
        handleClose={handleClosePatientHistoryModal}
        patientHistoryId={historyPatientId}
        patientHistory={historyPatient}
        isDisabled={true}
      />
    </DashboardLayout>
  );
};

export default HospitalWideData;
