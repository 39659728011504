import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Modal, Box, IconButton, Card, Grid, CircularProgress, TextField, Checkbox, FormControlLabel, Divider } from "@mui/material";

const PatientDetails = () => {
    const { id } = useParams();
    const [patient, setPatient] = useState(null);
    const [medicalHistory, setMedicalHistory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openSNOTModal, setOpenSNOTModal] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/get/${id}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setPatient(data.patient);
                }
            })
            .catch((error) => {
                console.error("Error fetching patient details:", error);
            });

        fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/patient-history/${id}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setMedicalHistory(data.patientHistory);
                }
            })
            .catch((error) => {
                console.error("Error fetching medical history:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);

    const renderSection = (title, children) => (
        <MDBox mb={3}>
            <MDTypography variant="h6" color="info" mb={2}>
                {title}
            </MDTypography>
            {children}
            <Divider sx={{ mt: 2 }} />
        </MDBox>
    );

    const handleOpenSNOTModal = () => setOpenSNOTModal(true);
    const handleCloseSNOTModal = () => setOpenSNOTModal(false);

    const SNOTModal = ({ snot }) => (
        <Modal
            open={openSNOTModal}
            onClose={handleCloseSNOTModal}
            aria-labelledby="snot-modal-title"
            aria-describedby="snot-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <MDTypography id="snot-modal-title" variant="h6" component="h2">
                    SNOT-22 Details
                </MDTypography>
                <MDBox id="snot-modal-description" sx={{ mt: 2 }}>
                    {Object.entries(snot).map(([key, value]) => (
                        <MDBox key={key} mb={1}>
                            <MDTypography variant="body2">{key}: {value}</MDTypography>
                        </MDBox>
                    ))}
                </MDBox>
            </Box>
        </Modal>
    );

    const renderMedicalHistoryForm = (history) => {
        return (
            <MDBox px={3} py={2}>
                {/* Symptom Duration Section */}
                {renderSection("Symptom Duration", (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Duration (months)"
                                value={history.SymptomDuration.Duration}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Pattern"
                                value={history.SymptomDuration.ContinuousOrIntermittent}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Previous Treatments Section */}
                {renderSection("Previous Treatments", (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Previous Treatments"
                                value={history?.PreviousTreatments?.FreeTextField}
                                fullWidth
                                disabled
                                multiline
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Medications Section */}
                {renderSection("Medications", (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.Medications?.OralCorticosteroids?.Used} disabled />}
                                label="Oral Corticosteroids"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.Medications?.TopicalCorticosteroids?.Used} disabled />}
                                label="Topical Corticosteroids"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.Medications?.Antihistamines?.Used} disabled />}
                                label="Antihistamines"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.Medications?.Decongestants?.Used} disabled />}
                                label="Decongestants"
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Biologic Therapies Section */}
                {renderSection("Biologic Therapies", (
                    <Grid container spacing={2}>
                        {Object.entries(history.BiologicTherapies).map(([therapy, details]) => (
                            <Grid item xs={12} md={4} key={therapy}>
                                <MDTypography variant="subtitle2">{therapy}</MDTypography>
                                <FormControlLabel
                                    control={<Checkbox checked={details.used} disabled />}
                                    label="Used"
                                />
                                {details.used && (
                                    <>
                                        <TextField
                                            label="Start Year"
                                            value={details.startYear}
                                            fullWidth
                                            disabled
                                            size="small"
                                            sx={{ mt: 1 }}
                                        />
                                        <TextField
                                            label="End Year"
                                            value={details.endYear}
                                            fullWidth
                                            disabled
                                            size="small"
                                            sx={{ mt: 1 }}
                                        />
                                        <TextField
                                            label="Switch"
                                            value={details.switch}
                                            fullWidth
                                            disabled
                                            size="small"
                                            sx={{ mt: 1 }}
                                        />
                                        {details.switchReason && (
                                            <TextField
                                                label="Switch Reason"
                                                value={details.switchReason}
                                                fullWidth
                                                disabled
                                                size="small"
                                                sx={{ mt: 1 }}
                                            />
                                        )}
                                    </>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ))}

                {/* Comorbidities Section */}

                {/* Comorbidities Section */}
                {renderSection("Comorbidities", (
                    <Grid container spacing={2}>
                        {Object.entries(history.Comorbidities).map(([comorbidity, value]) => {
                            if (typeof value === 'boolean') {
                                return (
                                    <Grid item xs={12} md={4} key={comorbidity}>
                                        <FormControlLabel
                                            control={<Checkbox checked={value} disabled />}
                                            label={comorbidity}
                                        />
                                    </Grid>
                                );
                            }
                            return null;
                        })}
                        {history.Comorbidities.Allergies.Used && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Specific Allergies"
                                    value={history.Comorbidities.Allergies.SpecificTypes.join(', ')}
                                    fullWidth
                                    disabled
                                    multiline
                                />
                            </Grid>
                        )}
                    </Grid>
                ))}

                {/* Medication Usage Section */}
                {renderSection("Medication Usage", (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.MedicationUsage?.Aspirin} disabled />}
                                label="Aspirin"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.MedicationUsage?.OtherNSAIDs} disabled />}
                                label="Other NSAIDs"
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Objective Examination Section */}
                {renderSection("Objective Examination", (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MDTypography variant="subtitle2" mb={1}>
                                Anterior Rhinoscopy Findings:
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.ObjectiveExamination?.AnteriorRhinoscopy?.PresenceOfPolyps} disabled />}
                                label="Presence of Polyps"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.ObjectiveExamination?.AnteriorRhinoscopy?.TurbinateHypertrophy} disabled />}
                                label="Turbinate Hypertrophy"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.ObjectiveExamination?.AnteriorRhinoscopy?.Secretions} disabled />}
                                label="Secretions"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDTypography variant="subtitle2" mb={1} mt={2}>
                                Nasal Endoscopy:
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Dx"
                                value={history.ObjectiveExamination.NasalEndoscopy.Dx}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Sx"
                                value={history.ObjectiveExamination.NasalEndoscopy.Sx}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Additional Investigations Section */}
                {renderSection("Additional Investigations", (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Rhinomanometry Results"
                                value={history?.AdditionalInvestigations?.Rhinomanometry?.Results}
                                fullWidth
                                disabled
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Lund McKay Score"
                                value={history.AdditionalInvestigations.LundMcKayScore}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="OSS Test Result"
                                value={history.AdditionalInvestigations.OssTest}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDBox display="flex" alignItems="center">
                                <MDTypography variant="subtitle2" mr={1}>
                                    SNOT-22 Score: {history.AdditionalInvestigations.SNOT.totalSNOTScore}
                                </MDTypography>
                                <IconButton onClick={handleOpenSNOTModal} size="small">
                                    <VisibilityIcon />
                                </IconButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                ))}



                {/* Surgical Interventions Section */}
                {renderSection("Surgical Interventions", (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={history.HadSurgicalIntervention} disabled />}
                                label="Had Surgical Intervention"
                            />
                        </Grid>
                        {history.HadSurgicalIntervention && history.SurgicalInterventions.map((intervention, index) => (
                            <React.Fragment key={intervention._id}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Procedure Type"
                                        value={intervention.ProcedureType}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Year"
                                        value={intervention.Year}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Histological Report"
                                        value={intervention.HistologicalReport}
                                        fullWidth
                                        disabled
                                        multiline
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                ))}

                {/* Patient Expectations Section */}
                {renderSection("Patient Expectations", (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.AdditionalInformation?.PatientExpectations?.TherapyGoals?.ImprovementOfSymptoms} disabled />}
                                label="Improvement of Symptoms"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.AdditionalInformation?.PatientExpectations?.TherapyGoals?.ReductionInNeedForMedications} disabled />}
                                label="Reduction in Need for Medications"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={history?.AdditionalInformation?.PatientExpectations?.TherapyGoals?.ReductionInNeedForSurgicalInterventions} disabled />}
                                label="Reduction in Need for Surgery"
                            />
                        </Grid>
                    </Grid>
                ))}

                <SNOTModal snot={history.AdditionalInvestigations.SNOT} />
            </MDBox>
        );
    };

    if (isLoading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
                    <CircularProgress />
                </MDBox>
            </DashboardLayout>
        );
    }

    if (!patient) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDTypography>Patient not found</MDTypography>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                Patient Medical History
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3}>
                                {medicalHistory && medicalHistory.length > 0 ? (
                                    renderMedicalHistoryForm(medicalHistory[0])
                                ) : (
                                    <MDTypography textAlign="center" py={3}>
                                        No medical history available for this patient.
                                    </MDTypography>
                                )}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
};

export default PatientDetails;