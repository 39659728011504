import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Autocomplete } from "@mui/material";
import "./index.css"

const validateUserCreds = ({
  name,
  email,
  password,
  surname,
  specialization,
  dob,
  licenseNo,
  city,
}) => {
  const errors = {};

  if (!name) {
    errors.name = "Name is required";
  } else if (!/^[a-zA-Z]+$/.test(name)) {
    errors.name = "Name must contain only letters";
  }

  if (!surname) {
    errors.surname = "Surname is required";
  } else if (!/^[a-zA-Z]+$/.test(surname)) {
    errors.surname = "Surname must contain only letters";
  }

  if (!dob) {
    errors.dob = "dob is required";
  }
  if (!email) {
    errors.email = "Email is required";
  } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
    errors.email = "Email is invalid";
  }

  if (!password) {
    errors.password = "Password is required";
  } else if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(
      password
    )
  ) {
    errors.password = `Password must be 8-16 characters long, and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.`;
  }

  if (!specialization) {
    errors.specialization = "Specialization is required";
  } 
  // else if (!/^[a-zA-Z\s]+$/.test(specialization)) {
  //   errors.specialization =
  //     "Specialization must contain only letters and spaces";
  // }

  if (!licenseNo) {
    errors.licenseNo = "License number is required";
  } else if (!/^[a-zA-Z0-9]+$/.test(licenseNo)) {
    errors.licenseNo = "License number must be alphanumeric";
  }

  if (!city) {
    errors.city = "City is required";
  } else if (!/^[a-zA-Z\s]+$/.test(city)) {
    errors.city = "City must contain only letters and spaces";
  }

  return errors;
};

function Cover() {
  // const [userCred, setUserCred] = useState({name:'',email: '',password: '',city:'',surname:'',specialization:'',dob:'',licenseNo:''});
  const [userCred, setUserCred] = useState({
    name: "",
    email: "",
    password: "",
    city: "",
    surname: "",
    specialization: "",
    dob: null,
    licenseNo: "",
    hospital: "",
  });

  const [responseError, setResponseError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false); // State for managing modal
  const [checkedState, setCheckedState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [hospitalOptions, setHospitalOptions] = useState([]);
const specializations = [
  { label: 'Allergologia e Immunologia Clinica', value: 'allergologia_e_immunologia_clinica' },
  { label: 'Anatomia Patologica', value: 'anatomia_patologica' },
  { label: 'Anestesia, Rianimazione, Terapia Intensiva e del Dolore', value: 'anestesia_rianimazione_terapia_intensiva_dolore' },
  { label: 'Audiologia e Foniatria', value: 'audiologia_e_foniatria' },
  { label: 'Cardiochirurgia', value: 'cardiochirurgia' },
  { label: 'Cardiologia', value: 'cardiologia' },
  { label: 'Chirurgia Generale', value: 'chirurgia_generale' },
  { label: 'Chirurgia Maxillo-Facciale', value: 'chirurgia_maxillo_facciale' },
  { label: 'Chirurgia Pediatrica', value: 'chirurgia_pediatrica' },
  { label: 'Chirurgia Plastica, Ricostruttiva ed Estetica', value: 'chirurgia_plastica_ricostruttiva_ed_estetica' },
  { label: 'Chirurgia Toracica', value: 'chirurgia_toracica' },
  { label: 'Chirurgia Vascolare', value: 'chirurgia_vascolare' },
  { label: 'Dermatologia e Venereologia', value: 'dermatologia_e_venereologia' },
  { label: 'Ematologia', value: 'ematologia' },
  { label: 'Endocrinologia e Malattie del Metabolismo', value: 'endocrinologia_e_malattie_del_metabolismo' },
  { label: 'Farmacologia Clinica', value: 'farmacologia_clinica' },
  { label: 'Gastroenterologia', value: 'gastroenterologia' },
  { label: 'Genetica Medica', value: 'genetica_medica' },
  { label: 'Geriatria', value: 'geriatria' },
  { label: 'Ginecologia e Ostetricia', value: 'ginecologia_e_ostetricia' },
  { label: 'Igiene e Medicina Preventiva', value: 'igiene_e_medicina_preventiva' },
  { label: 'Malattie dell\'Apparato Respiratorio', value: 'malattie_dell_apparato_respiratorio' },
  { label: 'Malattie Infettive', value: 'malattie_infettive' },
  { label: 'Medicina del Lavoro', value: 'medicina_del_lavoro' },
  { label: 'Medicina dello Sport e dell\'Esercizio Fisico', value: 'medicina_dello_sport_e_esercizio_fisico' },
  { label: 'Medicina Fisica e Riabilitativa', value: 'medicina_fisica_e_riabilitativa' },
  { label: 'Medicina Interna', value: 'medicina_interna' },
  { label: 'Medicina Legale', value: 'medicina_legale' },
  { label: 'Medicina Nucleare', value: 'medicina_nucleare' },
  { label: 'Medicina Termale', value: 'medicina_termale' },
  { label: 'Medicina Trasfusionale', value: 'medicina_trasfusionale' },
  { label: 'Nefrologia', value: 'nefrologia' },
  { label: 'Neurochirurgia', value: 'neurochirurgia' },
  { label: 'Neurologia', value: 'neurologia' },
  { label: 'Neuropsichiatria Infantile', value: 'neuropsichiatria_infantile' },
  { label: 'Oftalmologia', value: 'oftalmologia' },
  { label: 'Oncologia Medica', value: 'oncologia_medica' },
  { label: 'Ortopedia e Traumatologia', value: 'ortopedia_e_traumatologia' },
  { label: 'Otorinolaringoiatria', value: 'otorinolaringoiatria' },
  { label: 'Patologia Clinica e Biochimica Clinica', value: 'patologia_clinica_e_biochimica_clinica' },
  { label: 'Pediatria', value: 'pediatria' },
  { label: 'Psichiatria', value: 'psichiatria' },
  { label: 'Radiodiagnostica', value: 'radiodiagnostica' },
  { label: 'Radioterapia', value: 'radioterapia' },
  { label: 'Reumatologia', value: 'reumatologia' },
  { label: 'Urologia', value: 'urologia' }
];

  
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/recommend-hospital`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setHospitalOptions(data.hospitals); // Set hospital options
        }
      })
      .catch((error) => console.error("Error fetching hospitals:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserCred((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDateChange = (date) => {
    setUserCred((prevState) => ({
      ...prevState,
      dob: date, // Update dob as a Date object
    }));

    // Clear the error for the dob field as the user selects a date
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      dob: "", // Remove error message for dob
    }));
  };

  const handleHospitalChange = (event, newValue) => {
    setUserCred((prevState) => ({
      ...prevState,
      hospital: newValue, // Update hospital in userCred
    }));
  };


  const handleClick = () => {
    const errors = validateUserCreds(userCred);
    setIsLoading(true);

    if (Object.keys(errors).length === 0 && checkedState) {
      fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userCred),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            navigate("/authentication/sign-in");
          } else {
            setIsLoading(false);
            // Check for specific error messages from backend and display them
            if (data.message) {
              toast.error(data.message); // Display error message as a toast
            } else {
              toast.error("An error occurred. Please try again.");
            }
          }
        })
        .catch((error) => {
          console.log("sign up error", error);
          toast.error("Failed to register, please try again.");
          setIsLoading(false);
        });
    } else {
      setValidationErrors(errors);
      console.log(errors, "errors", checkedState, "checkedState");
      if (checkedState === false && Object.keys(errors).length === 0) {
        toast.error("You Need to Accept the Terms and Condition");
      }
      setResponseError("Please correct the errors below.");
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            display="block"
            variant="button"
            color="red"
            my={1}
            px={3}
          >
            {responseError}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="First Name"
                name="name"
                variant="standard"
                fullWidth
                onChange={handleChange}
              // error={!!validationErrors.name}
              />
              {validationErrors.name && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.name}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="text"
                label="Last Name"
                name="surname"
                variant="standard"
                fullWidth
                onChange={handleChange}
              // error={!!validationErrors.surname}
              />
              {validationErrors.surname && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.surname}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <DatePicker
                selected={userCred.dob}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()} // Restrict to past dates
                placeholderText="dd/MM/yyyy"
                customInput={
                  <TextField
                    label="Date of Birth"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
              <br />
              {validationErrors.dob && (
                <MDTypography
                  variant="caption"
                  color="red"
                  style={{ color: "red", paddingLeft: "5px" }}
                >
                  {validationErrors.dob}
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="City"
                name="city"
                variant="standard"
                fullWidth
                onChange={handleChange}
              // error={!!validationErrors.city}
              />
              {validationErrors.city && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.city}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
            <FormControl fullWidth variant="standard">
  <InputLabel className="inputLabel-text"  >Specialization</InputLabel>
  <Select
    label="Specialization"
    name="specialization"
    value={userCred.specialization}
    onChange={handleChange}
    className="specialization-select" // Add a class here
    MenuProps={{
      PaperProps: {
        className: "specialization-dropdown", // Add class for dropdown
      },
    }}
  >
    {specializations.map((spec) => (
      <MenuItem key={spec.value} value={spec.value}>
        {spec.label}
      </MenuItem>
    ))}
  </Select>
</FormControl>
        {validationErrors.specialization && (
          <MDTypography variant="caption" style={{ color: "red" }}>
            {validationErrors.specialization}
          </MDTypography>
        )}
      </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Medical Licence Number"
                name="licenseNo"
                variant="standard"
                fullWidth
                onChange={handleChange}
              // error={!!validationErrors.licenseNo}
              />
              {validationErrors.licenseNo && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.licenseNo}
                </MDTypography>
              )}
            </MDBox>
            
            <MDBox mb={2}>
              <Autocomplete
                options={hospitalOptions}
                freeSolo
                onChange={handleHospitalChange}
                renderInput={(params) => (
                  <TextField {...params} label="Hospital" variant="standard" fullWidth />
                )}
                getOptionLabel={(option) => option || ""}
                onInputChange={(event, newInputValue) => {
                  setUserCred((prevState) => ({
                    ...prevState,
                    hospital: newInputValue,
                  }));
                }}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                variant="standard"
                fullWidth
                onChange={handleChange}
              // error={!!validationErrors.email}
              />
              {validationErrors.email && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.email}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              {/* Password Suggestions */}
              <Box
                sx={{
                  border: "1px solid #ccc", // Border for the box
                  borderRadius: "8px", // Rounded corners
                  padding: "8px", // Reduced padding inside the box
                  backgroundColor: "#f9f9f9", // Light background color
                  mb: 2, // Margin bottom to space it from other fields
                  mt: "31px",
                }}
              >
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Password must be 8-16 characters long, and contain at least
                  one digit, one lowercase letter, one uppercase letter, and one
                  special character(! @ # $ %)
                </Typography>
              </Box>

              {/* Password Input */}
              <MDInput
                type={!showPassword ? "text" : "password"}
                label="Password"
                name="password"
                variant="standard"
                fullWidth
                onChange={handleChange}
                // error={!!validationErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Error message */}
              {validationErrors.password && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.password}
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                name="confirmPassword"
                variant="standard"
                fullWidth
                onChange={handleChange}
                // error={!!validationErrors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {!showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {validationErrors.confirmPassword && (
                <MDTypography variant="caption" style={{ color: "red" }}>
                  {validationErrors.confirmPassword}
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                checked={checkedState}
                onChange={() => setCheckedState(!checkedState)}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="span"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                onClick={handleOpen}
                sx={{ cursor: "pointer" }}
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleClick}
              >
                {isLoading ? (
                  <div className="loader_wrapper">
                    <CircularProgress style={{ height: "20px" }} />
                  </div>
                ) : (
                  "Sign Up"
                )}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {/* Terms and Conditions Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="terms-modal-title" variant="h5" component="h2">
            Terms and Conditions
          </Typography>
          <Typography
            id="terms-modal-description"
            sx={{ mt: 2, textAlign: "justify", fontSize: "15px" }}
          >
            <span style={{ fontWeight: 'bold' }}>
              1. Introduzione
            </span>
            <br />
            Questi termini e condizioni regolano l'utilizzo del
            portale NPmonitor.it . Iscrivendosi al portale, l'utente (il medico)
            accetta di rispettare le seguenti condizioni. Si prega di leggere
            attentamente quanto segue prima di procedere.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              2. Responsabilità del Medico
            </span>
            <br />
            Il medico è responsabile del
            trattamento e della gestione dei dati sensibili dei propri pazienti
            in conformità alle normative vigenti, incluse ma non limitate a
            quelle stabilite dal GDPR e dalle leggi italiane sulla protezione
            dei dati personali. Il medico è tenuto a inserire solo informazioni
            veritiere e accurate sui propri pazienti. Qualsiasi abuso del
            sistema sarà sanzionato come previsto dalle normative in materia.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              3. Limiti di Responsabilità
            </span>
            <br />
            Il Dott. Domenico Di Maria non sarà
            responsabile per:
            <br />
            <ul style={{ paddingLeft: "33px" }}>
              <li> Interruzioni o malfunzionamenti del portale.</li>
              <li>
                Perdita di dati o danni derivanti da un uso improprio del
                portale.
              </li>
              <li>
                Violazioni della privacy o del trattamento dei dati a causa di
                negligenza da parte degli utenti del sistema.
              </li>
            </ul>
            <br />
            <span style={{ fontWeight: 'bold' }}>
              4. Trattamento dei Dati Sensibili
            </span>
            <br />
            Il medico è responsabile del
            trattamento dei dati sensibili dei pazienti, in conformità al GDPR e
            alle normative italiane sulla protezione dei dati personali. I dati
            sensibili devono essere trattati in modo sicuro e solo per scopi
            connessi alla gestione dei pazienti. Qualsiasi violazione delle
            normative vigenti comporterà la sospensione immediata dell'account e
            sarà soggetta a sanzioni legali.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              5. Sospensione o Cancellazione dell'Account
            </span>
            <br />
            L'Associazione degli
            Otorinolaringoiatri Italiani si riserva il diritto di sospendere o
            cancellare l'account del medico in caso di uso improprio del
            portale, inclusi ma non limitati a:
            <ul style={{ paddingLeft: "33px" }}>
              <li>Inserimento di dati falsi o inesatti.</li>
              <li>
                Uso del sistema per scopi diversi dalla gestione dei propri
              </li>
              pazienti.
              <li>
                Violazione delle norme di trattamento dei dati sensibili o delle
                leggi vigenti.
              </li>
            </ul>
            <br />
            <span style={{ fontWeight: 'bold' }}>
              6. Uso del Portale
            </span>
            <br />
            Il portale può essere utilizzato esclusivamente
            per la gestione dei pazienti da parte del medico. Qualsiasi altro
            utilizzo è espressamente vietato. Il medico è tenuto a mantenere la
            riservatezza delle proprie credenziali di accesso e a non
            condividerle con terzi.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              7. Proprietà Intellettuale
            </span>
            <br />
            Tutti i diritti relativi al portale,
            inclusi contenuti, dati e software, sono di proprietà del Dott.
            Domenico Di Maria. Il medico non acquisisce alcun diritto di
            proprietà sul sistema e sui dati generati o inseriti tramite il
            portale.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              8. Modifica dei Termini e Condizioni
            </span>
            <br />
            L'Associazione degli
            Otorinolaringoiatri Italiani si riserva il diritto di modificare
            questi termini e condizioni in qualsiasi momento. Eventuali
            modifiche saranno comunicate agli utenti tramite il portale e l'uso
            continuato del sistema da parte del medico implica l'accettazione
            delle nuove condizioni.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              9. Giurisdizione Legale
            </span>
            <br />
            Questi termini e condizioni sono regolati
            dalla legge italiana. In caso di controversie, le parti concordano
            che la giurisdizione competente sarà quella italiana.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              10. Risoluzione del Contratto
            </span>
            <br />
            Il medico può richiedere la
            risoluzione del contratto inviando una comunicazione via email
            all'Associazione degli Otorinolaringoiatri Italiani.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              11. Supporto e Assistenza
            </span>
            <br />
            Per qualsiasi problema tecnico o richiesta
            di supporto, il medico può contattare il Dott. Domenico Di Maria
            all’indirizzo https://www.domenicodimaria.it/scrivimi/
          </Typography>
        </Box>
      </Modal>
    </CoverLayout>
  );
}

export default Cover;
