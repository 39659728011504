import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  TextField,
} from "@mui/material";

const SNOT22Dialog = ({ onSave, values }) => {
  const [snotValues, setSnotValues] = useState({
    needToBlowNose: 0,
    sneezing: 0,
    runnyNose: 0,
    cough: 0,
    postNasalDischarge: 0,
    thickNasalDischarge: 0,
    earFullness: 0,
    dizziness: 0,
    earPainPressure: 0,
    facialPainPressure: 0,
    difficultyFallingAsleep: 0,
    wakingUpAtNight: 0,
    lackOfGoodNightsSleep: 0,
    wakingUpTired: 0,
    fatigueDuringDay: 0,
    reducedProductivity: 0,
    reducedConcentration: 0,
    frustratedIrritable: 0,
    sad: 0,
    embarrassed: 0,
    senseOfTasteSmell: 0,
    nasalBlockageCongestion: 0,
    totalSNOTScore: 0,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (values) {
      const newValues = {
        ...values,
        totalSNOTScore: Object.values(values)
          .slice(0, 22)
          .reduce((acc, curr) => acc + Number(curr), 0),
      };
      setSnotValues(newValues);
    }
  }, [values]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key, value) => {
    let validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 5);
    const updatedValues = { ...snotValues, [key]: validatedValue  };
    updatedValues.totalSNOTScore = Object.values(updatedValues)
      .slice(0, 22)
      .reduce((acc, curr) => acc + Number(curr), 0);
    setSnotValues(updatedValues);
  };

  const handleSave = () => {
    onSave(snotValues);
    setOpen(false);
  };

  // Function to get severity description
  const getSeverityDescription = (value) => {
    switch (Number(value)) {
      case 0:
        return "No problem";
      case 1:
        return "Very mild problem";
      case 2:
        return "Mild problem";
      case 3:
        return "Moderate problem";
      case 4:
        return "Severe problem";
      case 5:
        return "Problem as bad as it can be";
      default:
        return "";
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ color: "black" }}
      >
        {values ? "Edit SNOT-22 Scale" : "SNOT-22 Scale"}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {values ? "Edit SNOT-22 Assessment" : "New SNOT-22 Assessment"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "8px" }}>
          <Grid container spacing={2}>
            {[
              { label: "Need to blow nose", key: "needToBlowNose" },
              { label: "Sneezing", key: "sneezing" },
              { label: "Runny nose", key: "runnyNose" },
              { label: "Cough", key: "cough" },
              { label: "Post nasal discharge", key: "postNasalDischarge" },
              { label: "Thick nasal discharge", key: "thickNasalDischarge" },
              { label: "Ear fullness", key: "earFullness" },
              { label: "Dizziness", key: "dizziness" },
              { label: "Ear pain/pressure", key: "earPainPressure" },
              { label: "Facial pain/pressure", key: "facialPainPressure" },
              {
                label: "Difficulty falling asleep",
                key: "difficultyFallingAsleep",
              },
              { label: "Waking up at night", key: "wakingUpAtNight" },
              {
                label: "Lack of a good night’s sleep",
                key: "lackOfGoodNightsSleep",
              },
              { label: "Waking up tired", key: "wakingUpTired" },
              { label: "Fatigue during the day", key: "fatigueDuringDay" },
              { label: "Reduced productivity", key: "reducedProductivity" },
              { label: "Reduced concentration", key: "reducedConcentration" },
              {
                label: "Frustrated/restless/irritable",
                key: "frustratedIrritable",
              },
              { label: "Sad", key: "sad" },
              { label: "Embarrassed", key: "embarrassed" },
              { label: "Sense of taste/smell", key: "senseOfTasteSmell" },
              {
                label: "Blockage/congestion of nose",
                key: "nasalBlockageCongestion",
              },
            ].map((item, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  label={item.label}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 5 } }}
                  value={snotValues[item.key]}
                  onChange={(e) => handleChange(item.key, e.target.value)}
                  fullWidth
                />
                <div style={{ marginTop: "5px" }}>
                  <span>{getSeverityDescription(snotValues[item.key])}</span>
                </div>
              </Grid>
            ))}
          </Grid>

          <div style={{ marginTop: "20px" }}>
            <h4>Total SNOT-22 Score: {snotValues.totalSNOTScore}</h4>
          </div>
        </DialogContent>
        <Button onClick={handleSave} color="primary">
          {values ? "Update" : "Save"}
        </Button>
      </Dialog>
    </div>
  );
};

export default SNOT22Dialog;
